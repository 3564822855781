import React, { Component } from "react";
import { Link } from "gatsby";
import logo from "../assets/images/school_logo.jpg";
import { window, document } from 'browser-monads';

class NavTwo extends Component {
  constructor() {
    super();
    this.state = {
      sticky: false
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    //Mobile Menu
    this.mobileMenu();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true
      });
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false
      });
    }
  };

  mobileMenu = () => {
    //Mobile Menu Toggle
    if (typeof document != 'undefined') {
      let mainNavToggler = document.querySelector(".menu-toggler");
      let mainNav = document.querySelector(".main-navigation");

      mainNavToggler.addEventListener("click", function () {
        mainNav.style.display =
          mainNav.style.display !== "block" ? "block" : "none";
      });
    }
  };
  render() {
    return (
      <header className="site-header site-header__header-two ">
        <nav
          className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${this.state.sticky ? "stricked-menu stricky-fixed" : ""
            }`}
        >
          <div className="container clearfix">
            <div className="logo-box clearfix">
              <Link to="/" className="navbar-brand">
                <img
                  src={logo}
                  className="main-logo"
                  width="128"
                  alt="Awesome alter text"
                />
              </Link>
              <button className="menu-toggler" data-target=".main-navigation">
                <span className="kipso-icon-menu"></span>
              </button>
            </div>
            <div className="main-navigation">
              <ul className=" navigation-box">
                <li className="current">
                  <Link to="/">Home</Link>
                  {/* <ul className="sub-menu">
                    <li>
                      <Link to="/">Home 01</Link>
                    </li>
                    <li>
                      <Link to="/index-2">Home 02</Link>
                    </li>
                    <li>
                      <Link to="/index-3">Home 03</Link>
                    </li>
                    <li>
                      <a href="#none">Header Versions</a>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/">Header 01</Link>
                        </li>
                        <li>
                          <Link to="/index-2">Header 02</Link>
                        </li>
                        <li>
                          <Link to="/index-3">Header 03</Link>
                        </li>
                      </ul>
                    </li>
                  </ul> */}
                </li>
                <li>
                  <a href="#none">About</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/aboutchairman">Chairman's Message </Link>
                   </li>
                   {/*  <li>
                      <Link to="/aboutdirector">Director's Desk </Link>
                    </li>*/}
                    <li>
                      <Link to="/aboutprincipal">Principal's Desk </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="/">Academics</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/preprimary">Pre Primary</Link>
                    </li>
                    <li>
                      <Link to="/primary">Primary</Link>
                    </li>
                    <li>
                      <Link to="/secondary">Secondary</Link>
                    </li>
                    <li>
                      <Link to="/snrsecondary">Senior Secondary</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="#none">Student's Activities</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">Academic Planner </Link>
                    </li>
                    <li>
                      <Link to="/infrastructure">Infrastructure </Link>
                    </li>
                    <li>
                      <Link to="/cca">CCA </Link>
                    </li>
                    <li>
                      <Link to="/pricing">Sports  </Link>
                    </li>
                    <li>
                      <Link to="/events">Events  </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/infrastructure">Infrastructure</Link>
                </li>

                <li>
                  <Link to="/">Media Center</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/media">Media Coverage</Link>
                    </li>
                    <li>
                      <Link to="/teacher-details">Photo Gallery</Link>
                    </li>
                    <li>
                      <Link to="/become-teacher">Video Gallery</Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/news">Staff</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/news">Admin</Link>
                    </li>
                    <li>
                      <Link to="/news-details">Educators</Link>
                    </li>
                  </ul>
                </li> */}
                {/*                 
                <li>
                  <Link to="/contact">Contact</Link>
                </li> */}
              </ul>
            </div>
            <div className="right-side-box">
              <div className="header__social">
                <a href="https://twitter.com/suratsas">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.facebook.com/shantiasiaticsurat/">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a href="https://www.youtube.com/channel/UChJ3gkcAe2V8fwM2xjV4yYg">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.instagram.com/shanti_asiatic_school_surat/">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="site-header__decor">
          <div className="site-header__decor-row">
            <div className="site-header__decor-single">
              <div className="site-header__decor-inner-1"></div>
            </div>
            <div className="site-header__decor-single">
              <div className="site-header__decor-inner-2"></div>
            </div>
            <div className="site-header__decor-single">
              <div className="site-header__decor-inner-3"></div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default NavTwo;
