import React from "react";
import Layout from "../components/Layout";
import Topbar from "../components/Topbar";
import NavTwo from "../components/NavTwo";
import SliderOne from "../components/SliderOne";
import Footer from "../components/Footer";
import AboutTwo from "../components/AboutTwo";
import VideoOne from "../components/VideoOne";
import News from "../components/News";
import Testimonial from "../components/Testimonial"
import Activity from "../components/Activity";
import Chiripal from "../components/Chiripal";
import SchoolCategory from "../components/SchoolCategory";

const HomePage = () => (
  <Layout pageTitle="Shanti Asiatic School - Surat ">
    <Topbar />
    <NavTwo />
    <SliderOne />
    <SchoolCategory />
    <AboutTwo />
    <VideoOne />
    <Activity />
    <Testimonial />
    <Chiripal />
    <Footer />
  </Layout>
);

export default HomePage;
