import React from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper.min.css";
import testimonial1 from "../assets/images/testimonial_1.jpg";
import testimonial2 from "../assets/images/S1.jpeg";
import testimonial3 from "../assets/images/2.jpg";
import testimonial4 from "../assets/images/S2.jpeg";
import testimonial5 from "../assets/images/3.jpg";
import testimonial6 from "../assets/images/S3.jpeg";
import testimonial7 from "../assets/images/4.jpg";
import testimonial8 from "../assets/images/S4.jpeg";
import testimonial9 from "../assets/images/5.jpg";
import { Autoplay, Pagination, Navigation } from "swiper";

const Testimonial = () => {
  const params = {
    slidesPerView: 3,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 2
      },
      320: {
        slidesPerView: 1
      }
    }
  };

  return (
    <section className="testimonials-one testimonials-one__home-three">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Testimonials
          </h2>
        </div>
        <div className="testimonials-one__carousel">
          <Swiper
            {...params}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial1} alt="" height={550} />
              </div>
            </div>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial2} alt="" height={550} />
              </div>
            </div>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial3} alt="" height={550} />
              </div>
            </div>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial4} alt="" height={550} />
              </div>
            </div>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial5} alt="" height={550} />
              </div>
            </div>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial6} alt="" height={550} />
              </div>
            </div>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial7} alt="" height={550} />
              </div>
            </div>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial8} alt="" height={550} />
              </div>
            </div>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={testimonial9} alt="" height={550} />
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
