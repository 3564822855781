import React from "react";
import meeting1 from "../assets/images/about_03.jpg";
import activity2 from "../assets/images/singing_2.jpg";
import { Link } from "gatsby";
import chat1 from "../assets/images/chat-1-1.jpg";
import chat2 from "../assets/images/chat-1-2.jpg";
const Activity = () => {
  return (
    <section className="meeting-one">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="meeting-one__image">
              <img src={meeting1} alt="Awesome alter text" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="meeting-one__content">
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  Our Activities
                </h2>
              </div>
              <h2 className="meeting-one__title">
                Sports
              </h2>
              <p className="meeting-one__block-text">
                Cricket, Athletics, Table tennis, Chess, Skating, Basketball, Football, Karate.
              </p>

              <h2 className="meeting-one__title">
                Fine Arts
              </h2>
              <p className="meeting-one__block-text">
                Fine arts, Dance & Music, Dramatics, Rangoli making, Pottery.
              </p>

              <h2 className="meeting-one__title">
                Educational Field Trips
              </h2>
              <p className="meeting-one__block-text">
                Places & purpose of field trips.
              </p>

              <h2 className="meeting-one__title">
                Ppap
              </h2>
              <p className="meeting-one__block-text">
                A child Centric Concept, Actually Delivers On What The Learning Should Be. Overall Development Of Humanity Till Date Can Be Assigned To One Single Factor, i.e. Humans Unquenchable Desire To Learn And To Explore.
              </p>
              <a href="#none" className="thm-btn pricing-one__btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="meeting-one__content">
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  Vision
                </h2>
              </div>
              <p className="meeting-one__block-text">
                To Mould Young Minds Into Community Leaders.
              </p>
              <a href="#none" className="thm-btn pricing-one__btn">
                Learn More
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="meeting-one__image">
              <img src={activity2} alt="Awesome alter text" />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-6">
            <div className="meeting-one__image">
              <img src={meeting1} alt="Awesome alter text" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="meeting-one__content">
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  Mission
                </h2>
              </div>
              <p className="meeting-one__block-text">
                To Create A Joyful School Environment That Will Generate Happy, Rightous & Quality Individuals Equipped To Make Right Choice In Life & Grow Upto be Responsible Citizens Of The World.
              </p>
              <a href="#none" className="thm-btn pricing-one__btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Activity;
