import React from 'react';
import chiripal from "../assets/images/chiripal.jpg";

const Chiripal = () => {
    return (
        <section className="cta-one cta-one__home-one">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        {/* <div className="cta-one__btn-block"> */}
                        <div class="cta-one__btn-block">
                            <a href='#'>
                               <img src={chiripal} alt="" />
                            </a>
                        
                            {/* <a href="#none" class="thm-btn cta-one__btn">Learn More</a> */}
                        </div>
                            {/* <div className="mailchimp-one__icon">
                             <h2 className="about-one__text d-flex justify-content-center">Let’s do study with <br/>expert teachers</h2>
                            </div> */}
                            {/* <h2 className="mailchimp-one__title">Get latest courses <br />
                                updates by signing up</h2> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Chiripal;
