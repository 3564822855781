import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import video1 from "../assets/images/video-1-1.jpg";
import strech from "../assets/images/scratch-1-1.png";

const iframe = '<iframe width="560" height="315" src="https://www.youtube.com/embed/hNo44tMVsg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
class VideoOne extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    return (
      <section className="video-one">
        <div className="container">
          <img src={strech} className="video-one__scratch" alt="" />
          <div className="row">
            <div className="col-lg-6 d-flex align-items-end">
              <div className="video-one__content mr-5">
                <h2 className="video-one__title-aboutori">
                At SAS we believe that “Each child is a Winner”, and as a result our whole curriculum is structured
                around this idea. Our main responsibility is to unlock each child’s potential, which will inevitably
                result in learning being enjoyable. They develop a passion of learning when learning is enjoyable.
                </h2>
                {/* <a href="#none" className="thm-btn video-one__btn">
                  Learn More
                </a> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="video-one__img">
               <iframe width="560" height="315" src="https://www.youtube.com/embed/hNo44tMVsg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="hNo44tMVsg0"
                  onClose={() => this.setState({ isOpen: false })}
                />
                <div
                  onClick={this.openModal}
                  onKeyDown={this.openModal}
                  role="button"
                  tabIndex={0}
                  className="video-one__popup"
                >
                  <i className="fas fa-play"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default VideoOne;
