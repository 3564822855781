import React, { useState } from "react";
import Swiper from "react-id-swiper";
import { Link } from 'gatsby'
import "swiper/swiper.min.css";
import http from '../_api/http'

const SchoolCategory = () => {
  const [pdfsource, setPdf] = useState("");
  http
    .get('api/pdf/ApiPdfList?pdf_for_type=6&pdf_selct_for=Acandemic Planner')
    .then(res => {
      if (res.data.status === 200) {
        setPdf(
          process.env.REACT_APP_APIURL + 'files/' + res.data.pdf[0]?.name
        )
      }
    })
  const params = {
    slidesPerView: 5,
    loop: false,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 5
      },
      768: {
        slidesPerView: 4
      },
      640: {
        slidesPerView: 3
      },
      320: {
        slidesPerView: 2
      }
    }
  };

  return (
    <section className="course-category-two" style={{'marginTop': '115px'}}>
      <div className="container text-center">
        <div className="inner-container">
          <div className="course-category-two__carousel">
            <Swiper {...params}>
              <div className="item">
                <div className="course-category-two__single color-1">
                  <div className="course-category-two__icon">
                    <i className="kipso-icon-black-graduation-cap-tool-of-university-student-for-head"></i>
                  </div>
                  <h3 className="course-category-two__title">
                    <a
                      href={pdfsource}
                      target='_blank'
                      rel='noreferrer'
                    >Academic Planner</a>
                  </h3>
                </div>
              </div>
              <div className="item">
                <div className="course-category-two__single color-2">
                  <div className="course-category-two__icon">
                    <i className="kipso-icon-professor"></i>
                  </div>
                  <h3 className="course-category-two__title">
                    <Link to={'/majareventlist'}>Major Events</Link>
                  </h3>
                </div>
              </div>
              <div className="item">
                <div className="course-category-two__single color-3">
                  <div className="course-category-two__icon">
                    <i className="kipso-icon-music-player"></i>
                  </div>
                  <h3 className="course-category-two__title">
                    <Link to={'/cocurricular1'}>Co-Curricular</Link>
                  </h3>
                </div>
              </div>
              <div className="item">
                <div className="course-category-two__single color-4">
                  <div className="course-category-two__icon">
                    <i className="kipso-icon-targeting"></i>
                  </div>
                  <h3 className="course-category-two__title">
                    <Link to={'/competitions'}>Competitions</Link>
                  </h3>
                </div>
              </div>
              <div className="item">
                <div className="course-category-two__single color-5">
                  <div className="course-category-two__icon">
                    <i className="kipso-icon-web-programming"></i>
                  </div>
                  <h3 className="course-category-two__title">
                    <Link to={'/facility'}>Facilities</Link>
                  </h3>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SchoolCategory;
