import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import about1 from "../assets/images/WhereLearning.png";

export default class AboutTwo extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className="about-two">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-two__content">
                <div className="block-title text-left">
                  <h2 className="block-title__title title">
                    Where Learning And Development Is A Daily Ritual!
                  </h2>
                </div>
                <p className="about-two__text">
                  We, at Shanti Asiatic School Surat, believe education should be holistic and progressive that
                  transforms a child to become a successful global citizen of tomorrow. Hence, our approach and
                  outlook is a blend of values with a futuristic vision.<br />
                  Our curriculum is designed in a way that nourishes a child's all-round growth, putting innovation
                  at the center and building the foundation of morals and principles. The school has adopted an
                  evolving and innovative education methodology in a fun and engaging atmosphere.
                </p>
              </div>

            </div>
            <div className="col-xl-6 d-flex justify-content-xl-end justify-content-sm-center" >
              <div className="about-two__image hidden">
                <span className="about-two__image-dots"></span>
                <img src={about1} alt="" />
                <div className="about-two__count" style={{display:"none"}}>
                  <div className="about-two__count-text">
                    Trusted by
                    <span className="counter">
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp end={this.state.startCounter ? 1500 : 0} />
                      </VisibilitySensor>
                    </span>
                    Parents
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    );
  }
}
